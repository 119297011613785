import React, { useEffect, useState, useRef, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import { fetchDataBase } from "./redux/database/databaseActions";
import { fetchDataBurn } from "./redux/databurn/databurnActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import CircleTextLogo from "./CircleTextLogo";
import LazyAndPreloadImage from "./components/LazyAndPreloadImage";
import Box from "@mui/material/Box";
import { Grid } from "@material-ui/core";
import MuiPagination from "@material-ui/lab/Pagination";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  color: #fff;
  font-size: 2rem;
  font-family: DelaGothicOne;
  border-radius: 10px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonOparator = styled.button`
  color: #fff;
  font-size: 1rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonOparatorOff = styled.button`
  color: #fff;
  font-size: 1rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #333;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  width: 50%;
  position: relative;
  overflow: hidden;
`;

export const StyledButtonFree = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonvipwl = styled.button`
  padding: 10px;
  margin-left: 20px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledIncDecButton = styled.button`
  padding: 10px 18px !important;
  border-radius: 2px;
  margin: 0 2px;
  border: solid 1px #333;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 20px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  height: auto;
  @media (min-width: 767px) {
    width: 500px;
    height: auto;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  /* border: 4px dashed var(--secondary); */
  /* background-color: var(--accent); */
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledButtonReload = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 50%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledImgThanks = styled.img`
  /* box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary); */
  /* background-color: var(--accent);
  border-radius: 100%; */
  width: 200px;
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const StyledLinkMini = styled.a`
  color: var(--secondary);
  font-size: 0.5rem;
  text-decoration: none;
`;

export const BoxList = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 24px;
  border-radius: 24px;
  border: 4px solid #333;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TextDescription = styled.p`
  color: gray;
  font-size: 12px;
  padding: 1rem;
  line-height: 1.6;
`;

export const StyledTitles = styled.img`
  width: 100%;
  position: relative;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const StyledTitlesCover = styled.img`
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
`;

export const StyledDiverTitle = styled.img`
  width: 1.4rem;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transform: rotateY(45deg);
  animation: rotate-anime-3d 3s linear infinite;
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const TitlesTitle = styled.p`
  color: var(--accent-text);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledTitlesName = styled.p`
  /* background-color: var(--accent-text); */
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 24px 0;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;

export const StyledButtonWall = styled.button`
  color: #fff;
  font-size: 1rem;
  width: 100%;
  border-radius: 10px;
  padding: 1rem 0;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :hover {
    cursor: pointer;
  }
`;
function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const database = useSelector((state) => state.database);
  const databurn = useSelector((state) => state.databurn);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [mintAmount, setMintAmount] = useState(1);
  const [minted, setminted] = useState(0);
  const [numPage, setnumPage] = useState(20);
  const [mintedWl, setmintedWl] = useState(0);
  const [canFreeMint, setcanFreeMint] = useState(0);
  const [mintable, setMintable] = useState(0);
  const [whitelistProof, setwhitelistProof] = useState("");
  const [whitelistValid, setwhitelistValid] = useState(false);
  const [publistProof, setpublistProof] = useState("");
  const [publistValid, setpublistValid] = useState(false);
  const [publist2Valid, setpublist2Valid] = useState(false);
  //ページ番号
  const [page, setPage] = useState(1);

  const Pagination = withStyles({
    root: {
      display: "inline-block", //中央寄せのためインラインブロックに変更
      backgroundColor: "rgba(255,255,255,0.5)",
    },
  })(MuiPagination);

  const SelectRaw = withStyles({
    root: {
      display: "inline-block", //中央寄せのためインラインブロックに変更
      backgroundColor: "rgba(255,255,255,0.5)",
    },
  })(Select);

  const [lang, setlang] = useState(false);
  const [langData, setlangData] = useState({
    fail: "Failed,Please try again.",
    chkops: "you minted STARTJPN Ticket! check on your Opensea.",
    minting: "Minting ...",
    salestartwl: "Free Mint：12/26(Mon)PM8:00〜(JST)",
    salestartwl2: "",
    salestartpb: "Public Sale：3/2(Thu)PM9:00〜(JST)",
    soldout: "Sold Out! thank you!",
    wl: "Presale",
    pb: "Public Sale",
    gasfee: "+gas Fee",
    network: "to get STK,Please Connect to Ethereum Mainnet.",
    appAlert:
      "You cannot reapply for an application that has already been submitted.",
    busy: "Busy",
    minted: "minted",
    mintwl: "mintWL",
    mintps: "mintPS",
    youhavewl: "You are on the Whitelist!",
    thanksMinted: "Thank you for minting.",
    thanksMintedWl: "Thank you for minting AL.",
    pbnotStarted: "Public sale has not started",
    youhavenotwl: "You are not Whitelisted.",
    ntwalrt: "changed network.please try to connect.",
    tryconn: "Please try Connect.",
    reload: "reload",
    application: "application",
    applied: "applied",
    cancel: "cancel",
    displaymsg: "NFTs held are displayed.",
    hodltitle: "Your NFTs(You have ",
    tickets: " Tickets.)",
    ticketBurned: "1 ticket will be burned.",
    youhavepb: "you whitelisted to public Sale.",
    youhavepb2: "you whitelisted to 2nd public Sale.",
    ticketNotReturn:
      "you do not get your ticket back, but do you want to run it?",
    canceled: "The process was not executed.",
    comingsoon: "Coming soon...",
    totalApplication: "Total.App:",
    evos: "EVOs.",
  });
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 5000,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    MARKETPLACE_BASEURI: "https://opensea.io/collection/panlo-by-startph",
    SHOW_BACKGROUND: false,
  });

  const changeLang = () => {
    setlang(!lang);
    let langJson = require("./data/jp.json");
    if (lang) {
      langJson = require("./data/en.json");
    }
    setlangData(langJson);
    getInit();
  };
  const checkMinted = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .psMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setminted(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const getWhitelist = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let whitelist = require("./data/whitelist.json");

      let validNuns = whitelist.map((addr) => addr.slice(-64));
      let adresses = whitelist.map((addr) => addr.slice(0, -64));

      let index = adresses.indexOf(address);
      let validNun = validNuns[index];

      let mintNum = 0;
      if (validNun !== undefined) {
        // mintNum = validNun.slice(-1);
        mintNum = parseInt(validNun, 16);
      }

      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address + validNun);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('VIP:address/'+address+':::mintNum/'+mintNum);
      // console.log('VIP:hasheBefore/'+address+validNun);
      // console.log('VIP:hashed/'+hashedAddress);

      blockchain.smartContract.methods
        .isWhitelisted(blockchain.account, mintNum, proof)
        .call()
        .then((receipt) => {
          setwhitelistProof(proof);
          setwhitelistValid(receipt);
          setcanFreeMint(mintNum);
          if (canFreeMint > 0) {
            setMintable(canFreeMint - mintedWl);
          }
          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
        });
      // setwhitelistProof(proof);
      // setwhitelistValid(valid);
      // console.log("WL:wallet/" + blockchain.account);
      // console.log("WL:proof/" + proof);
      // console.log("WL:root/" + root);
      // console.log(whitelistValid);
    }
  };

  const getPblist = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");
      let publist;
      if (CONFIG.SALENUM == 1) {
        publist = require("./data/publist.json");
      } else {
        publist = require("./data/publist2.json");
      }
      let hashedAddresses = publist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();

      blockchain.smartContract.methods
        .isPWhitelisted(blockchain.account, proof)
        .call()
        .then((receipt) => {
          setpublistProof(proof);
          setpublistValid(receipt);
          dispatch(fetchData(blockchain.account));
        });
      // console.log("WL:wallet/" + blockchain.account);
      if (data.deployer == blockchain.account) {
        console.log("PB:proof/" + proof);
        console.log("PB:root/" + root);
      }
      // console.log(publistValid);
    }
  };

  const getPblist2 = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let publist = require("./data/publist2.json");
      let hashedAddresses = publist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      if (data.deployer == blockchain.account) {
        console.log("PB2:proof/" + proof);
        console.log("PB2:root/" + root);
      }
      let adresses = publist.map((addr) => addr.toLowerCase());
      let index = adresses.indexOf(address);
      let validNun = publist[index];
      if (CONFIG.SALENUM == 1) {
        if (validNun !== undefined) {
          setpublist2Valid(true);
        }
      }
    }
  };

  const burnWrite = async (tokenId) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    if (confirm(langData.ticketBurned)) {
      console.log("proceed");
    } else {
      alert(langData.canceled);
      return false;
    }

    if (
      datasub.balanceOf > 0 &&
      databurn.firstIndex != false &&
      databurn.burnWriteEnable
    ) {
      let method = await blockchain.smartContract.methods.burnWrite(
        tokenId,
        databurn.firstIndex
      );
      let gas = await method.estimateGas({ from: blockchain.account });
      console.log("estimateGas: " + gas);
      let gasPrice = await blockchain.web3.eth.getGasPrice();
      gasPrice = Math.round(gasPrice * 1.3);
      console.log("gasPrice: " + gasPrice);
      console.log("Cost: ", 0);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback(`Burning your ${CONFIG.NFT_NAME}...`);
      setClaimingNft(true);
      blockchain.smartContract.methods
        .burnWrite(tokenId, databurn.firstIndex)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: 0,
          gasPrice: gasPrice,
          gas: gas + 50000,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
            `Application has been completed.`
          );
          setClaimingNft(false);
          checkMinted();
          refreshMetadata(tokenId);
          // dispatch(fetchData(blockchain.account));
        });
    } else {
      alert("You not have Tickets.");
    }
  };

  const burnWriteRemove = (tokenId) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    if (confirm(langData.ticketNotReturn)) {
      console.log("proceed");
    } else {
      alert(langData.canceled);
      return false;
    }

    if (databurn.burnWriteRemoveEnable) {
      console.log("Cost: ", 0);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback(`Burning your ${CONFIG.NFT_NAME}...`);
      setClaimingNft(true);
      blockchain.smartContract.methods
        .burnRemoveWrite(tokenId)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: 0,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
            `cancel has been completed.`
          );
          setClaimingNft(false);
          checkMinted();
          // dispatch(fetchData(blockchain.account));
        });
    } else {
      alert("Operation currently not available.");
    }
  };

  const sendquest = (questId) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    if (
      datasub.balanceOf > 0 &&
      databurn.firstIndex != false &&
      databurn.burnWriteQEnable
    ) {
      let ticketsArray = databurn.walletOfOwner.slice(0, 3);
      console.log("Cost: ", 0);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
      setClaimingNft(true);
      blockchain.smartContract.methods
        .burnWriteMultiQues(questId, ticketsArray)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: 0,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
            `Application has been completed.`
          );
          setClaimingNft(false);
          checkMinted();
          // dispatch(fetchData(blockchain.account));
        });
    } else {
      alert("You not have Tickets.");
    }
  };
  // setPublicSaleEnable

  const setPublicSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPublicMPSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にPBセール開始されました！")
            : setFeedback("正常にPBセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const setBWEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Enable BurnWrite...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setBurnWriteEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にBurnWrite開始されました！")
            : setFeedback("正常にBurnWrite停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const setBWREnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Enable BurnWrite...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setBurnWriteRemoveEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にBurnWrite開始されました！")
            : setFeedback("正常にBurnWrite停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const setWLSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setWhitelistSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にWLセール開始されました！")
            : setFeedback("正常にWLセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const withdraw = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`出金中 ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .withdraw()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常に出金されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };
  const checkMintedwl = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .wlMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setmintedWl(receipt);
          if (canFreeMint > 0) {
            setMintable(canFreeMint - mintedWl);
          }
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const setBaseUrl = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`ベースURL設定します ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setBaseURI("ar://1OZNxScRLDelLrU33Axew3DZIaA8lCqHf7XzBmD3hBk/0/")
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にベースURL設定されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTswl = () => {
    let cost = 0;
    let gasLimit = CONFIG.GAS_LIMIT;
    let mintableNum = canFreeMint - mintedWl;
    let totalCostWei = String(cost * mintableNum);
    // let totalGasLimit = String(gasLimit * mintAmountwl);
    let gasMulti = 1;
    gasMulti = 0.01 * mintableNum;
    gasMulti += 1;
    let totalGasLimit = String(gasLimit * gasMulti);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    // console.log(canFreeMint, whitelistProof);

    blockchain.smartContract.methods
      .whitelistMint(mintableNum, canFreeMint, whitelistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かが間違っているようです。再度お試しください。");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMintedwl();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    let gasMulti = 1;
    gasMulti = 0.01 * mintAmount;
    gasMulti += 1;
    let totalGasLimit = String(gasLimit * gasMulti);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMintMP(mintAmount, publistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          langData.chkops
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const refreshMetadata = (tokenId) => {
    axios
      .get(CONFIG.OPENSEAAPI + tokenId + CONFIG.OPENSEAAPIPOS)
      .then((response) => {
        console.log(response.data);
      });
  };

  const decrementMintAmount = (diffNum) => {
    let newMintAmount = mintAmount - diffNum;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = (diffNum) => {
    let newMintAmount = mintAmount + diffNum;
    let maxAmount = CONFIG.MAX_MINTPB - minted;
    if (maxAmount < 0) {
      maxAmount = 1;
    }
    if (maxAmount > CONFIG.MAX_MINT) {
      maxAmount = CONFIG.MAX_MINT;
    }
    if (newMintAmount > maxAmount) {
      newMintAmount = maxAmount;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getDataSub = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataSub(blockchain.account));
    }
  };
  const getDataBase = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataBase(blockchain.account));
    }
  };
  const setNumPageAndMore = (e) => {
    setnumPage(e.target.value);
    setPage(1);
  };

  const getDataBurn = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataBurn(blockchain.account));
    }
  };

  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`Click "Mint" to mint your NFT.`);
      setMintAmount(1);
      setminted(0);
    } else {
      getDataSub();
      getDataBase();
      getDataBurn();
    }
    getData();
    getWhitelist();
    getPblist();
    getPblist2();
    checkMinted();
    checkMintedwl();
  };
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getReload = () => {
    location.reload();
  };

  async function intervalGet() {
    const intervalId = setInterval(() => {
      getData();
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }
  useEffect(() => {
    getConfig();
    checkMinted();
    checkMintedwl();
    getInit();
    intervalGet();
  }, []);

  useEffect(() => {
    getInit();
  }, [blockchain.account]);

  useEffect(() => {
    if (claimingNft == false) {
      getInit();
    }
  }, [claimingNft]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "rgba(0, 0, 0,0)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
      >
        <ul className="bgtrans">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <StyledLogo alt={"logo"} src={"/startlogo.png"} />
        {/* <StyledLogo alt={"logo"} src={null} /> */}
        <ResponsiveWrapper
          flex={1}
          style={{ padding: 24, background: "" }}
          test
        >
          {/* {isMobile ? (<></>) : (<>

            <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/logo.png"} />
          </s.Container>
          </>)} */}
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <CircleTextLogo />
            {/* <StyledImg alt={"example"} src={"/config/images/logo.png"} /> */}
          </s.Container>

          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(51,51,51,0.7)",
              padding: 24,
              borderRadius: 24,
              // border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            {blockchain.account === "" ||
            blockchain.smartContract === null ||
            blockchain.account === undefined ? (
              <></>
            ) : (
              <>
                {blockchain.account ===
                "0xb4250f715995683c6ea5bc7c5e2cdf9b1601ba3f" ? (
                  <>
                    <s.TextTitle
                      style={{ textAlign: "center", color: "white" }}
                    >
                      {"KOUSEIさんようこそ"}
                    </s.TextTitle>
                  </>
                ) : (
                  <></>
                )}
                {data.deployer == blockchain.account ? (
                  <>
                    {!databurn.burnWriteEnable ? (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          setBWEnable(true);
                          getInit();
                        }}
                      >
                        {claimingNft ? langData.busy : "EVO申請開始"}
                      </StyledButtonOparator>
                    ) : (
                      <>
                        <StyledButtonOparatorOff
                          onClick={(e) => {
                            e.preventDefault();
                            setBWEnable(false);
                            getInit();
                          }}
                        >
                          {claimingNft ? langData.busy : "EVO申請終了"}
                        </StyledButtonOparatorOff>
                      </>
                    )}
                    <s.SpacerSmall />
                    {!databurn.burnWriteEnable ? (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          setBWREnable(true);
                          getInit();
                        }}
                      >
                        {claimingNft ? langData.busy : "EVOキャンセル開始"}
                      </StyledButtonOparator>
                    ) : (
                      <>
                        <StyledButtonOparatorOff
                          onClick={(e) => {
                            e.preventDefault();
                            setBWREnable(false);
                            getInit();
                          }}
                        >
                          {claimingNft ? langData.busy : "EVOキャンセル終了"}
                        </StyledButtonOparatorOff>
                      </>
                    )}{" "}
                    <s.SpacerSmall />
                    <s.SpacerSmall />
                    {/* {!data.isWlEnabled ? (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          setWLSaleEnable(true);
                          getInit();
                        }}
                      >
                        {claimingNft ? langData.busy : "WLセール開始(26日20時)"}
                      </StyledButtonOparator>
                    ) : (
                      <>
                        <StyledButtonOparatorOff
                          onClick={(e) => {
                            e.preventDefault();
                            setWLSaleEnable(false);
                            getInit();
                          }}
                        >
                          {claimingNft ? langData.busy : "WLセール停止(未定)"}
                        </StyledButtonOparatorOff>
                      </>
                    )}
                    <s.SpacerSmall /> */}
                    {!data.isPsEnabled ? (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          setPublicSaleEnable(true);
                          getInit();
                        }}
                      >
                        {claimingNft ? langData.busy : "PBセール開始(未定)"}
                      </StyledButtonOparator>
                    ) : (
                      <>
                        <StyledButtonOparatorOff
                          onClick={(e) => {
                            e.preventDefault();
                            setPublicSaleEnable(false);
                            getInit();
                          }}
                        >
                          {claimingNft ? langData.busy : "PBセール停止"}
                        </StyledButtonOparatorOff>
                      </>
                    )}
                    <s.SpacerSmall />
                    <StyledButtonOparator
                      onClick={(e) => {
                        e.preventDefault();
                        withdraw();
                        getInit();
                      }}
                    >
                      {claimingNft ? langData.busy : "出金(任意)"}
                    </StyledButtonOparator>
                  </>
                ) : (
                  <></>
                )}{" "}
              </>
            )}

            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              LANG/言語
            </s.TextTitle>
            <div class="toggle-switch">
              <input
                id="toggleBox"
                class="toggle-input"
                type="checkbox"
                style={{ display: "none" }}
                onChange={(e) => {
                  // e.preventDefault();
                  changeLang();
                }}
              />
              <label for="toggleBox" class="toggle-label" />
            </div>

            <s.TextTitle style={{ textAlign: "center", color: "white" }}>
              STARTJPN Ticket
            </s.TextTitle>
            {/* <s.TextTitle style={{ textAlign: "center", color: "white" }}>
              {langData.salestartwl}
            </s.TextTitle> */}
            <s.TextTitle style={{ textAlign: "center", color: "white" }}>
              {langData.salestartpb}
            </s.TextTitle>

            {isMobile ? (
              <>
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 25,
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  {25650 - datasub.currentIndex <= 0 ? (
                    <>
                      {data.mintable} / {data.mintable}
                    </>
                  ) : (
                    <>
                      {data.totalSupply} / {data.mintable}
                    </>
                  )}
                </s.TextTitle>
              </>
            ) : (
              <>
                {" "}
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 50,
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  {25650 - datasub.currentIndex <= 0 ? (
                    <>
                      {data.mintable} / {data.mintable}
                    </>
                  ) : (
                    <>
                      {data.totalSupply} / {data.mintable}
                    </>
                  )}
                </s.TextTitle>
              </>
            )}
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {publistValid ? (
              <>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  {langData.youhavepb}
                </s.TextDescription>
                <s.SpacerSmall />
              </>
            ) : (
              <></>
            )}
            {publist2Valid ? (
              <>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  {langData.youhavepb2}
                </s.TextDescription>
                <s.SpacerSmall />
              </>
            ) : (
              <></>
            )}

            {Number(data.totalSupply) >= data.mintable ||
            25650 - datasub.currentIndex <= 0 ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {langData.soldout}
                </s.TextTitle>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.SYMBOL} costs
                </s.TextTitle>
                {/* <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {"AL:FREE"} {langData.gasfee}
                </s.TextTitle> */}
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {"Public:"}
                  {CONFIG.DISPLAY_COST}
                  {CONFIG.NETWORK.SYMBOL}
                  {langData.gasfee}
                  <s.SpacerXSmall />

                  {"(Max "}
                  {CONFIG.MAX_MINT}
                  {"mint/1time)"}
                </s.TextTitle>
                <s.SpacerXSmall />

                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ||
                blockchain.account === undefined ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getInit();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    {/* <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {"接続アドレス："+blockchain.account}
                    </s.TextDescription> */}
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>

                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    ></s.TextDescription>
                    {data.isPsEnabled ||
                    (whitelistValid && data.isWlEnabled) ? (
                      <>
                        <s.TextTitle
                          style={{
                            textAlign: "center",
                            fontSize: 25,
                            fontWeight: "bold",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"Now On Sale!"}
                        </s.TextTitle>
                      </>
                    ) : (
                      <>
                        <s.TextTitle
                          style={{
                            textAlign: "center",
                            fontSize: 20,
                            fontWeight: "bold",
                            color: "var(--accent-text)",
                          }}
                        >
                          {langData.comingsoon}
                        </s.TextTitle>
                      </>
                    )}

                    {/* アローリスト所有者の方ここから */}
                    {whitelistValid ? (
                      <>
                        {data.isPsEnabled ? (
                          <></>
                        ) : (
                          <>
                            {/* <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {langData.youhavewl}
                              {"("}
                              {mintedWl}
                              {"/"}
                              {canFreeMint}
                              {")"}
                            </s.TextDescription> */}
                          </>
                        )}

                        {data.isWlEnabled ? (
                          <>
                            <s.SpacerMedium />
                            {canFreeMint <= mintedWl ? (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {langData.thanksMintedWl}
                                  </s.TextDescription>
                                </s.Container>
                              </>
                            ) : (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <StyledButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTswl();
                                      getInit();
                                    }}
                                  >
                                    {claimingNft
                                      ? langData.busy
                                      : canFreeMint - mintedWl + " MINT"}
                                  </StyledButton>
                                </s.Container>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {langData.freegasFee}
                                  </s.TextDescription>
                                </s.Container>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <s.SpacerMedium />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {/* {"アローリストセールは開始していません"} */}
                            </s.TextDescription>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {/* <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"このアドレスはアローリストに含まれていません。"}
                        </s.TextDescription> */}
                      </>
                    )}
                    {/* ここからパブリックセール */}
                    <s.SpacerMedium />
                    {publistValid && data.isPsEnabled ? (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {langData.pb}
                          {"("}
                          {minted}
                          {"minted)"}
                        </s.TextDescription>

                        {CONFIG.MAX_MINTPB <= minted ? (
                          <>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {langData.thanksMinted}
                            </s.TextDescription>
                          </>
                        ) : (
                          <>
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              {/* <StyledIncDecButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount(100);
                                }}
                              >
                                -100
                              </StyledIncDecButton> */}
                              <StyledIncDecButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount(10);
                                }}
                              >
                                -10
                              </StyledIncDecButton>
                              <StyledIncDecButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount(1);
                                }}
                              >
                                -1
                              </StyledIncDecButton>
                              <s.SpacerMedium />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                  padding: 20,
                                }}
                              >
                                {mintAmount}
                              </s.TextDescription>
                              <s.SpacerMedium />
                              <StyledIncDecButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount(1);
                                }}
                              >
                                +1
                              </StyledIncDecButton>
                              <StyledIncDecButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount(10);
                                }}
                              >
                                +10
                              </StyledIncDecButton>
                              {/* <StyledIncDecButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount(100);
                                }}
                              >
                                +100
                              </StyledIncDecButton> */}
                            </s.Container>
                            <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFTs();
                                  getInit();
                                }}
                              >
                                {claimingNft
                                  ? langData.busy
                                  : mintAmount + " MINT"}
                              </StyledButton>
                            </s.Container>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {publistValid ? (
                          <>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {langData.pbnotStarted}
                            </s.TextDescription>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />

            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              NFT Market Place
            </s.TextDescription>
            <s.SpacerSmall />
            <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
              {CONFIG.MARKETPLACE}
            </StyledLink>
            <s.SpacerLarge />

            <s.Container ai={"center"} jc={"center"} fd={"row"}>
              <StyledButtonReload
                onClick={(e) => {
                  e.preventDefault();
                  getReload();
                }}
              >
                {claimingNft ? langData.busy : "RELOAD"}
              </StyledButtonReload>
            </s.Container>
          </s.Container>
          {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/circle.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container> */}
          {/* {isMobile ? (<></>) : (<>
            <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/logo.png"} />
          </s.Container>
          </>)} */}
          <s.Container flex={1} jc={"center"} ai={"center"}>
            {/* <StyledImg alt={"example"} src={"/config/images/logo.png"} /> */}
            {isMobile ? (
              <></>
            ) : (
              <>
                <CircleTextLogo />
              </>
            )}
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />

        {databurn.burnWriteEnable || true ? (
          <>
            <s.SpacerLarge />

            <BoxList>
              <Grid container direction="column">
                <Grid item container>
                  <Grid xs={12} sm={12}>
                    {database.unload ? (
                      <>
                        <TitlesTitle
                          id="Titles"
                          style={{
                            textAlign: "center",
                            perspective: "500px",
                            color: "primary.main",
                          }}
                        >
                          {langData.displaymsg}
                        </TitlesTitle>
                      </>
                    ) : (
                      <>
                        <TitlesTitle
                          id="Titles"
                          style={{
                            textAlign: "center",
                            perspective: "500px",
                            color: "primary.main",
                            fontSize: "1.4rem",
                          }}
                        >
                          {langData.totalApplication}
                          {databurn.requests.length}
                          {langData.evos}
                        </TitlesTitle>
                        <TitlesTitle
                          id="Titles"
                          style={{
                            textAlign: "center",
                            perspective: "500px",
                            color: "primary.main",
                          }}
                        >
                          {langData.hodltitle}
                          {datasub.balanceOf}
                          {langData.tickets}
                        </TitlesTitle>
                        <div>
                          {/* Select の基本 */}
                          <SelectRaw
                            onChange={(e) => setNumPageAndMore(e)} //変更されたときに走る関数。第2引数にページ番号が入る
                            style={{
                              textAlign: "center",
                              backgroundColor: "rgba(255,255,255,0.5)",
                              perspective: "500px",
                              color: "primary.main",
                            }}
                          >
                            <MenuItem value={5} disabled={5 == numPage}>
                              5件(raw)
                            </MenuItem>
                            <MenuItem value={20} disabled={20 == numPage}>
                              20件(raw)
                            </MenuItem>
                            <MenuItem value={50} disabled={50 == numPage}>
                              50件(raw)
                            </MenuItem>
                            <MenuItem value={80} disabled={80 == numPage}>
                              80件(raw)
                            </MenuItem>
                            <MenuItem value={100} disabled={100 == numPage}>
                              100件(raw)
                            </MenuItem>
                            <MenuItem value={150} disabled={150 == numPage}>
                              150件(raw)
                            </MenuItem>
                            <MenuItem value={200} disabled={200 == numPage}>
                              200件(raw)
                            </MenuItem>
                          </SelectRaw>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <Pagination
                            count={Math.ceil(
                              (database.imageURI.slice(-1)[0][0] + 1) / numPage
                            )} //総ページ数
                            color="secondary" //ページネーションの色
                            shape="rounded"
                            onChange={(e, page) => setPage(page)} //変更されたときに走る関数。第2引数にページ番号が入る
                            page={page} //現在のページ番号
                          />
                        </div>
                        <s.SpacerLarge />
                      </>
                    )}

                    {database.loading ? (
                      <>
                        <TitlesTitle
                          id="Titles"
                          style={{
                            textAlign: "center",
                            perspective: "500px",
                            color: "primary.main",
                          }}
                        >
                          {"Loading..."}
                        </TitlesTitle>
                      </>
                    ) : (
                      <></>
                    )}

                    <Grid xs={12} sm={12}>
                      <Box
                        sx={{
                          width: 1 / 1.25,
                          height: 1 / 1,
                          mx: "auto",
                          backgroundColor: "black",
                          "&:hover": {
                            backgroundColor: "primary.main",
                            opacity: [0.9, 0.8, 0.7],
                          },
                        }}
                      ></Box>
                    </Grid>
                  </Grid>
                  {database.imageURI != undefined
                    ? database.imageURI?.map((imageURI) => (
                        <>
                          {(imageURI[0] + 1) / numPage > page - 1 &&
                          (imageURI[0] + 1) / numPage <= page ? (
                            <>
                              {" "}
                              <Suspense fallback={<></>}>
                                <Grid
                                  xs={6}
                                  sm={2}
                                  key={imageURI[0]}
                                  style={{
                                    perspective: "500px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: 1 / 1.25,
                                      height: 1 / 1,
                                      mx: "auto",
                                      display: "block",
                                      backgroundColor: "none",
                                      transformStyle: "preserve-3d",
                                      animation: "fadein 1s linear forwards",
                                      opacity: "0",
                                      // animationDelay: imageURI[0] + "s",
                                      "&:hover": {
                                        backgroundColor: "primary.main",
                                        opacity: [0.9, 0.8, 0.7],
                                      },
                                    }}
                                  >
                                    <LazyAndPreloadImage
                                      alt={"logo" + imageURI[0]}
                                      src={imageURI[1]}
                                    />
                                    {/* <StyledTitles alt={"logo"} src={imageURI[1]} /> */}
                                    {databurn.requests.indexOf(imageURI[3]) >=
                                    0 ? (
                                      <>
                                        <StyledTitlesCover
                                          alt={"logo"}
                                          src={"/config/images/startrocket.png"}
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    <StyledLink
                                      target={"_blank"}
                                      href={
                                        CONFIG.MARKETPLACE_BASEURI + imageURI[3]
                                      }
                                    >
                                      <StyledTitlesName>
                                        {imageURI[2]}
                                      </StyledTitlesName>
                                    </StyledLink>
                                    {databurn.burnWriteEnable ? (
                                      <>
                                        {databurn.requests.indexOf(
                                          imageURI[3]
                                        ) >= 0 ? (
                                          <>
                                            <StyledButtonWall
                                              onClick={(e) => {
                                                e.preventDefault();
                                                refreshMetadata(imageURI[3]);
                                                alert(langData.appAlert);
                                                getInit();
                                              }}
                                            >
                                              {"EVO"}
                                              <br />
                                              {langData.applied}
                                            </StyledButtonWall>
                                            {databurn.burnWriteRemoveEnable ? (
                                              <>
                                                <StyledButtonWall
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    burnWriteRemove(
                                                      imageURI[3]
                                                    );
                                                    getInit();
                                                  }}
                                                >
                                                  {"EVO"}
                                                  <br />
                                                  {langData.cancel}
                                                </StyledButtonWall>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <StyledButtonWall
                                              onClick={(e) => {
                                                e.preventDefault();
                                                burnWrite(imageURI[3]);
                                                getInit();
                                              }}
                                            >
                                              {"EVO"}
                                              <br />
                                              {langData.application}
                                            </StyledButtonWall>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Box>
                                </Grid>
                              </Suspense>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ))
                    : ""}
                </Grid>
              </Grid>
              <s.SpacerLarge />
            </BoxList>
          </>
        ) : (
          <></>
        )}
        {/* <StyledButtonWall
          onClick={(e) => {
            e.preventDefault();
            sendquest(1);
            getInit();
          }}
        >
          クエスト消費
        </StyledButtonWall> */}
      </s.Container>
    </s.Screen>
  );
}

export default App;
